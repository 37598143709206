@tailwind base;
@tailwind components;
@tailwind utilities;

h1,h2, h3, button, input, .chat,.tooltip {
  font-family: Roboto Mono, Helvetica, sans-serif;
}

body {
  font-family: Roboto Flex, Helvetica, sans-serif;
}

.chat-view-messages > :nth-child(even) {
  background: #2c2c2c;
}

.chat-view-message {
  width: 100%;
  margin: 0;
}