.radio[type='radio'] {
  @apply appearance-none inline-block cursor-pointer leading-4 outline-none align-top absolute left-0 w-5 h-5 top-[22px];
}

.radio[type='radio']:before,
.radio[type='radio']:after {
  @apply absolute left-0 top-0 w-5 h-5 rounded-full -translate-y-1/2 content-[''];
}

.radio[type='radio']:before {
  @apply shadow-focus shadow-white;
}

.radio[type='radio'].error:before {
  @apply shadow-focus shadow-[#E34646];
}

.radio[type='radio']:hover:before {
  --color-one: hsla(var(--base-color-white), 100%, 0.3);
  --color-two: hsla(var(--base-color-white), 100%, 1);
  --color-three: hsla(var(--base-color-medium-gray), 18%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow: 0 0 0 8px var(--color-one), inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three), inset 0 0 0 10px var(--color-two);
}

.radio[type='radio']:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 100%, 0.6);
  --color-two: hsla(var(--base-color-white), 100%, 1);
  --color-three: hsla(var(--base-color-medium-gray), 18%, 0.4);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: Normal;
  animation-timing-function: ease-in;

  box-shadow: 0 0 0 8px var(--color-one), inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three), inset 0 0 0 10px var(--color-two);
}

.radio[type='radio']:checked:before {
  --color-one: hsla(var(--base-color-blue), 63%, 1);
  --color-two: hsla(var(--base-color-medium-gray), 18%, 1);

  box-shadow: inset 0 0 0 2px var(--color-one), inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].withGrayBg:checked:before {
  --color-one: hsla(var(--base-color-blue), 63%, 1);
  --color-two: hsla(var(--base-color-white), 27%, 1);
}

.radio[type='radio']:checked:hover:before {
  --color-one: hsla(var(--base-color-blue), 63%, 0.3);
  --color-two: hsla(var(--base-color-blue), 63%, 1);
  --color-three: hsla(var(--base-color-medium-gray), 18%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: Normal;
  animation-timing-function: ease-in;

  box-shadow: 0 0 0 8px var(--color-one), inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three), inset 0 0 0 10px var(--color-two);
}

.radio[type='radio']:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-blue), 63%, 0.6);
  --color-two: hsla(var(--base-color-blue), 63%, 1);
  --color-three: hsla(var(--base-color-medium-gray), 18%, 0.4);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: Normal;
  animation-timing-function: ease-in;

  box-shadow: 0 0 0 8px var(--color-one), inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three), inset 0 0 0 10px var(--color-two);
}


@keyframes expand-radio {
  0% {
    @apply shadow-focus shadow-white;
  }

  25% {
    box-shadow: 0 0 0 3px var(--color-one), inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three), inset 0 0 0 10px var(--color-two);
  }

  55% {
    box-shadow: 0 0 0 5px var(--color-one), inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three), inset 0 0 0 10px var(--color-two);
  }

  85% {
    box-shadow: 0 0 0 7px var(--color-one), inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three), inset 0 0 0 10px var(--color-two);
  }

  100% {
    box-shadow: 0 0 0 8px var(--color-one), inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three), inset 0 0 0 10px var(--color-two);
  }
}
